.pointer {
    cursor: pointer;
}

.rot-180 {
    transform: rotate(180deg);
}

.flex-vertical-center {
    display: flex;
    vertical-align: center;
}
.vis-hidden {
    visibility: hidden;
}
