:root {
    --arrow-box-space: 13px;
}

.icon-container-cst-max {
    height: var(--arrow-box-space);
    width: var(--arrow-box-space);
}

.icon-sorting-cst {
    margin-left: 2px;
    padding-top: 2px;
}

.pr-14 {
    padding-right: 14px;
}

