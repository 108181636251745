@font-face {
    font-family: 'FFDINWebProLight';
    src:url('3D8EB0_0_0_custom.woff2') format('woff2'),url('3D8EB0_0_0_custom.woff') format('woff');
    font-display: swap;
}
@font-face {
    font-family: 'FFDINWebProRegular';
    src:url('3D8EB0_1_0_custom.woff2') format('woff2'),url('3D8EB0_1_0_custom.woff') format('woff');
    font-display: swap;
}
@font-face {
    font-family: 'FFDINWebProBold';
    src:url('3D8EB0_2_0_custom.woff2') format('woff2'),url('3D8EB0_2_0_custom.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'FFDINWebProLightItalic';
    src:url('3D8EB0_3_0_custom.woff2') format('woff2'),url('3D8EB0_3_0_custom.woff') format('woff');
    font-display: swap;
}
